import React from 'react';
import { Accordion } from 'semantic-ui-react';

import type { History } from 'history';

import EnvSettings from 'src/api/EnvSettings';
import {
  findPhoneConfiguration,
  getIntegrationData,
  parsePhoneConfiguration,
  shouldCreateAPhoneCallTicket
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';

import type { CallRequestPayload } from 'src/actions/phoneActions';
import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { PhoneConfiguration } from 'src/types/PhoneConfiguration';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData } from 'src/types/User';
import type { EnreachStatus, EnreachVoiceEvent } from 'src/Utilities/intergrations/callEnreachVoice';

interface EnreachVoiceProps {
  callRequests: CallRequestPayload[];
  configurations: PhoneConfiguration[];
  history: History;
  isOpen: boolean;
  ticketTypes: TicketType[];
  userData: PersonalData;

  handleIncomingPhoneCall: (callObject: IntegrationData) => Promise<void>;
  setIsOpen: (active: boolean) => void;
  setEnreachVoiceLoggedIn: (loggedIn: boolean) => void;
  setEnreachVoiceStatus: (status: EnreachStatus) => void;
}

class EnreachVoiceComponent extends React.Component<EnreachVoiceProps> {
  private enreachVoiceIntegration: EnreachVoiceIntegration | null = null;

  constructor(props: EnreachVoiceProps) {
    super(props);
  }

  componentDidMount() {
    this.enreachVoiceIntegration = new EnreachVoiceIntegration();

    this.enreachVoiceIntegration.on('onload', this.handleEnreachVoiceOnLoad);
    this.enreachVoiceIntegration.on('call-incoming', this.handleIncomingRing);
    this.enreachVoiceIntegration.on('call-answered', this.handleIncomingCall);
    this.enreachVoiceIntegration.on('logged-in', this.props.setEnreachVoiceLoggedIn);
    this.enreachVoiceIntegration.on('status', this.props.setEnreachVoiceStatus);
  }

  componentWillUnmount() {
    this.enreachVoiceIntegration?.destroy();
  }

  private handleEnreachVoiceOnLoad = () => {
    this.addCustomStyles();
  };

  private handleIncomingRing = async (event: Event) => {
    const { queueName } = (event as EnreachVoiceEvent).detail;
    const configuration = findPhoneConfiguration(this.props.configurations, queueName);

    if (configuration?.configuration.newTicketOnRinging) {
      await this.handleCall(event);
    }
  };

  private handleIncomingCall = async (event: Event) => {
    const { queueName } = (event as EnreachVoiceEvent).detail;
    const configuration = findPhoneConfiguration(this.props.configurations, queueName);

    if (!configuration?.configuration.newTicketOnRinging) {
      await this.handleCall(event);
    }
  };

  private handleCall = async (event: Event) => {
    const { queueName, phoneNumber: originalPhoneNumber, callId, callDirection } = (event as EnreachVoiceEvent).detail;
    const normalizedPhoneNumber = normalizePhoneNumber(originalPhoneNumber);
    const direction = callDirection === 'outbound' ? 'out' : 'in';
    const configuration = findPhoneConfiguration(this.props.configurations, queueName);
    const parsedConfiguration = parsePhoneConfiguration(configuration);

    const UID = this.props.userData.UID;
    const history = this.props.history;

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'enreachVoiceId',
        updateValue: callId,
        group: 'CaseDetails'
      }
    ];

    const callObject: IntegrationData = getIntegrationData({
      UID,
      configuration,
      detailsObjects,
      direction,
      integrationPayload: {
        foreignId: callId,
        foreignIdType: 'vfbCallId',
        CallId: callId,
        QueueId: (event as EnreachVoiceEvent).detail.queueId,
        QueueName: (event as EnreachVoiceEvent).detail.queueName
      },
      history,
      normalizedPhoneNumber,
      parsedConfiguration,
      serviceName: queueName,
      ticketTypes: this.props.ticketTypes,
      userDefaultTicketTypeId: this.props.userData.userPreferences.defaultTicketType
    });

    if (shouldCreateAPhoneCallTicket(configuration, parsedConfiguration)) {
      // When no configuration is found, or when configuration does not prevent creation
      await this.props.handleIncomingPhoneCall(callObject);
    }
  };

  private addCustomStyles = () => {
    const webrtc = EnreachVoiceIntegration.getWebRTC();
    const style = document.createElement('style');
    style.innerHTML = '.phone__wrapper { width: 100vw !important };';

    const webrtcClientContainer = webrtc?.shadowRoot?.querySelector('bvfb-webrtc-client-container');
    const webrtcClient = webrtcClientContainer?.shadowRoot?.querySelector('bvfb-webrtc-client');
    webrtcClient?.shadowRoot?.prepend(style);
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  render() {
    return (
      <>
        <Accordion.Title className="ticketlist ticketlist_OC" active={this.props.isOpen} onClick={this.toggleAccordion}>
          Voice
        </Accordion.Title>
        <Accordion.Content active={this.props.isOpen}>
          <div>
            <iframe
              allowFullScreen={false}
              frameBorder={0}
              id="EnreachVoice"
              scrolling="yes"
              src={`/enreachVoice.html?version=${EnvSettings.getSettings().ENREACH_VOICE_VERSION}`}
              title="Voice"
              allow="microphone"
              style={{
                width: '100%',
                height: '552px',
                flex: '1 1 auto',
                display: 'flex'
              }}
            />
          </div>
        </Accordion.Content>
      </>
    );
  }
}

export default EnreachVoiceComponent;
